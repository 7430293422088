import {Model} from "../vuemc";

export default class Login extends Model
{
    defaults() {
        return {
            email:    null,
            password: null,
            remember: false,
        }
    }

    getFormData() {
        let loginFormData = {
            email: this.email,
            password: this.password,
        };

        // set remember flag only if it is ticked, Laravel use `filled` method here
        if (this.remember) {
            loginFormData.remember = true;
        }

        return loginFormData;
    }
}
